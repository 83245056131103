<template>
  <!-- component -->
<div class="dark:bg-gray-900">
    <div class="min-h-screen flex items-center justify-center">
        <div class="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h1 class="text-xl font-semibold mb-4 text-black">Resetear contraseña</h1>
            <p class="text-gray-600 mb-6">Ingresa la dirección de correo asociado a tu cuenta para solicitar un reinicio de contraseña.</p>
            <div class="mb-4">
                <input type="email" placeholder="correo@ejemplo.com" class="email-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:border-blue-500" />
            </div>
            <button class="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none">Enviar</button>
        </div>
    </div>
</div>
  </template>
  
  <script>
  //import VerificarDoble from './VerificarDoble.vue'; // Asegúrate de que la ruta es correcta
  import UAParser from 'ua-parser-js';

  export default {
    components: {
      //VerificarDoble
    },
    data() {
      return {
        email: '',
        message: '',
        isError: false,
        show2FA: false, // Estado para manejar la visualización del 2FA
        resetToken: '' // Para almacenar el token de restablecimiento si se requiere
      };
    },
    methods: {
      async requestPasswordReset() {
        try {
          // Obtención de datos de `platform.js`

        // Obtención de datos de `UAParser`
        const parser = new UAParser();
        const uaResult = parser.getResult();
        const uaData = {
          browserName: uaResult.browser.name || 'unknown',
          deviceType: uaResult.device.type || 'unknown',
          deviceModel: uaResult.device.model || 'unknown',
          deviceVendor: uaResult.device.vendor || 'unknown',
          osName: uaResult.os.name || 'unknown',
          osVersion: uaResult.os.version || 'unknown'
        };

        // Envío de datos a través del POST
        const response = await this.$axios.post('/request-password-reset', {
          email: this.email,// Información del dispositivo de `platform.js`
          uaData         // Información del dispositivo de `UAParser`
        });
  
          if (response.data.requires2FA) {
            this.show2FA = true; // Mostrar el componente 2FA
            this.$store.commit('setEmail', this.email);
          } else {
            this.message = response.data.message;
            this.isError = false;
          }
        } catch (error) {
          if (error.response && error.response.data) {
            this.message = error.response.data.message || 'Error al solicitar el restablecimiento de la contraseña.';
            this.isError = true;
          } else {
            this.message = 'Error al comunicarse con el servidor.';
            this.isError = true;
          }
        }
      },
      handle2FASuccess(data) {
        this.message = data.message;
        this.isError = false;
        // Redirigir al usuario a la página de restablecimiento de contraseña con el token
        //this.$router.push({ name: 'ResetPassword', query: { token: this.resetToken } });
      }
    }
  };
  </script>
  
  <style scoped>
  /* Mantén los estilos existentes */
  </style>
 