<template>
  <div class="login-box-container">
    <div class="login-box">
      <p>Agregar Fondo de Inversión</p>

      <!-- Select para seleccionar el grupo de inversión -->
      <div class="user-box">
        <v-select
          v-model="grupoSeleccionado"
          :options="gruposFiltrados"
          label="nombre_grupo"
          placeholder="Selecciona un grupo de inversión"
        ></v-select>
      </div>

      <!-- Select para filtrar y seleccionar tipo de fondo -->
      <div class="user-box">
        <v-select
          v-model="filtroTipoFondo"
          :options="tiposDeFondosFiltrados"
          @input="filtrarFondos"
          placeholder="Selecciona un tipo de fondo"
        ></v-select>
      </div>

      <!-- Select para seleccionar fondo, filtrado por tipo -->
      <div class="user-box">
        <v-select
          v-model="fondoSeleccionado"
          :options="fondosFiltrados"
          label="nombre"
          placeholder="Selecciona un fondo"
        ></v-select>
      </div>

      <!-- Mostrar información adicional del fondo seleccionado -->
      <div v-if="fondoSeleccionado" class="fondo-info">
        <p>Moneda: {{ informacionFondo.moneda }}</p>
        <p>Tipo de Fondo: {{ informacionFondo.tipo }}</p>
      </div>

      <!-- Campos de entrada -->
      <div class="user-box">
        <h3>Monto en Pesos</h3>
        <input v-model="monto" type="number" min="0" step="1000" required />
      </div>
      <div class="user-box">
        <h3>Cantidad</h3>
        <input v-model="cantidad" type="number" min="0" step="0.01" required />
      </div>
      <div class="user-box">
        <h3>Precio de Compra</h3>
        <input v-model="precioCompra" type="number" min="0" step="0.01" required />
      </div>

      <button @click="agregarFondo" type="button" class="submit-button">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        Agregar Fondo
      </button>

      <!-- Mensajes de éxito o error -->
      <div v-if="mensajeExito" class="success">{{ mensajeExito }}</div>
      <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      fondos: [], // Se llenará con los fondos obtenidos del servidor
      grupos: [], // Se llenará con los grupos obtenidos del servidor
      filtroTipoFondo: null, // Filtrar por tipo de fondo
      fondoSeleccionado: null, // Fondo seleccionado
      grupoSeleccionado: null, // Grupo seleccionado
      informacionFondo: {
        moneda: '',
        tipo: ''
      },
      monto: '',
      cantidad: '',
      precioCompra: '',
      mensajeExito: '',
      errorMessage: ''
    };
  },
  computed: {
    tiposDeFondosFiltrados() {
      // Obtener tipos de fondo únicos para el select
      return [...new Set(this.fondos.map(fondo => fondo.tipo))];
    },
    fondosFiltrados() {
      // Filtrar los fondos según el tipo seleccionado
      if (this.filtroTipoFondo) {
        return this.fondos.filter(fondo => fondo.tipo === this.filtroTipoFondo);
      }
      return this.fondos;
    },
    gruposFiltrados() {
      // Devuelve todos los grupos disponibles
      return this.grupos;
    }
  },
  watch: {
    fondoSeleccionado(newFondo) {
      if (newFondo) {
        // Actualizar la información del fondo cuando se selecciona uno
        this.informacionFondo.moneda = newFondo.moneda;
        this.informacionFondo.tipo = newFondo.tipo;
      } else {
        this.informacionFondo = { moneda: '', tipo: '' };
      }
    }
  },
  mounted() {
    this.obtenerFondosDelServidor();
    this.obtenerGruposDelServidor(); // Llamada para obtener los grupos al montar el componente
  },
  methods: {
    async obtenerFondosDelServidor() {
      try {
        const response = await this.$axios.get('/fondos-todos');
        // Actualizar la lista de fondos con los datos recibidos del servidor
        this.fondos = response.data.fondos || [];
      } catch (error) {
        this.errorMessage = 'Error al obtener los fondos del servidor.';
      }
    },
    async obtenerGruposDelServidor() {
      try {
        const response = await this.$axios.get('/grupos-todos'); // Endpoint para obtener grupos
        // Actualizar la lista de grupos con los datos recibidos del servidor
        this.grupos = response.data.grupos || [];
      } catch (error) {
        this.errorMessage = 'Error al obtener los grupos del servidor.';
      }
    },
    async agregarFondo() {
      // Validar que todos los campos estén completos
      if (this.grupoSeleccionado && this.fondoSeleccionado && this.monto && this.cantidad && this.precioCompra) {
        try {
          // Hacer la petición POST usando Axios
          const response = await this.$axios.post('/agregar-fondo', {
            grupoId: this.grupoSeleccionado.id, // Enviar el ID del grupo seleccionado
            fondoId: this.fondoSeleccionado.id, // Enviar el ID del fondo seleccionado
            monto: this.monto,
            cantidad: this.cantidad,
            precioCompra: this.precioCompra
          });
          
          // Manejo de la respuesta exitosa
          this.mensajeExito = response.data.message || 'Fondo agregado con éxito.';
          this.errorMessage = '';

          // Limpiar los campos después de agregar
          this.fondoSeleccionado = null;
          this.grupoSeleccionado = null;
          this.monto = '';
          this.cantidad = '';
          this.precioCompra = '';
        } catch (error) {
          // Manejo de errores
          if (error.response && error.response.data) {
            this.errorMessage = error.response.data.message || 'Error al agregar el fondo.';
          } else {
            this.errorMessage = 'Error al comunicar con el servidor.';
          }
          this.mensajeExito = '';
        }
      } else {
        this.errorMessage = 'Por favor completa todos los campos.';
        this.mensajeExito = '';
      }
    },
    filtrarFondos() {
      // Limpiar selección de fondo cuando se filtra por tipo
      this.fondoSeleccionado = null;
    }
  }
};
</script>

<style scoped>
/* Estilos del formulario aquí */
</style>
