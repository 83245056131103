<template>
    <div class="flex min-h-screen bg-black">
      <div class="mx-auto">
        <!-- Imagen con borde degradado -->
        <div class="mx-auto mb-[40px] mt-[98px] h-[213px] w-[213px] overflow-hidden rounded-full bg-gradient-to-tr from-[#FF8660] to-[#8000FF]" style="display: flex; justify-content: center; align-items: center;">
          <img src="../assets/2r6D.gif" alt="Profile Image" width="140" height="140"  />
        </div>
        <!-- Título principal -->
      <h1 class="max-w-screen-sm text-center text-[60px] font-extrabold text-white">
        Visualiza todas tus <br />
        <span class="text-transparent bg-clip-text bg-gradient-to-tr from-[#FF8660] to-[#8000FF]">inversiones</span> en un solo lugar 
      </h1>
      <!-- Descripción -->
      <p class="mx-auto mt-[40px] mb-[54px] text-[18px] font-light text-[#C5C5C5] text-center max-w-lg">
        Registra y monitorea todas tus inversiones en fondos chilenos e internacionales, con reportes detallados para medir tu rentabilidad.
      </p>
      <!-- Botones -->
      <div class="text-center">
        <button class="text-gray-800 inline-block px-[30px] py-[16px] mr-[18px] bg-white rounded-full font-semibold text-[22px]">
            <router-link
          to="/register"
        >
          Crear cuenta
        </router-link>
        </button>
        <button class="text-white inline-block px-[30px] py-[16px] bg-transparent border-2 rounded-full font-semibold text-[22px]">
            <router-link
          to="/login"
        >
          Ingresar
        </router-link>
        </button>
      </div>
      <p class="mx-auto mt-[40px] mb-[54px] text-[18px] font-light text-[#C5C5C5] text-center max-w-lg">
        Registrarse es totalmente gratis.
      </p>
    </div>
  </div>

  <section class="bg-gray-50 dark:bg-white">
  <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
    <div class="max-w-screen-md mb-8 lg:mb-16">
      <h2 class="mb-4 text-4xl font-extrabold text-gray-900 dark:text-black">
        Variedad de productos
      </h2>
      <p class="text-gray-500 sm:text-xl dark:text-gray-900">
        Accede fácilmente a una vista completa de todos tus fondos e
        instrumentos de inversión, para que siempre estés informado.
      </p>
    </div>
    <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
      <div>
        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50">
		<g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
			<path stroke="white" d="M27.667 14.583s5.979-.479 8.5 1.188a7.38 7.38 0 0 0 5.5.896" />
			<path stroke="white" d="m27.667 14.583l1.062-5.875a2.082 2.082 0 0 0-2.062-2.458h-7.5a2.083 2.083 0 0 0-2.084 2.458l1.084 5.875zm-4.75 29.167a30 30 0 0 0 8.729-1.187a8.33 8.33 0 0 0 5.854-7.959a14.3 14.3 0 0 0-1.5-6.25l-6.25-12.5a2.08 2.08 0 0 0-1.875-1.146h-9.917a2.08 2.08 0 0 0-1.875 1.146l-6.25 12.5a14.3 14.3 0 0 0-1.5 6.25v0a8.33 8.33 0 0 0 5.855 7.959a30 30 0 0 0 8.729 1.187" />
		</g>
	</svg>
        </div>
        
        <h3 class="mb-2 text-xl font-bold dark:text-black">Fondo Mutuo</h3>
        <p class="text-gray-500 dark:text-gray-900">
          Monitorea y administra tus fondos mutuos, con actualizaciones en tiempo real de tu rendimiento.
        </p>
      </div>
      <div>
        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50">
		<g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
			<path stroke="white" d="m35.854 18.75l3.73-5.333l-10.23-7.167l-8.77 12.5zM18.938 8.333L11.625 18.75h8.958L25 12.5z" />
			<path stroke="white" d="M41.667 18.75H8.333c-1.15 0-2.083.933-2.083 2.083v20.834c0 1.15.933 2.083 2.083 2.083h33.334c1.15 0 2.083-.933 2.083-2.083V20.833c0-1.15-.933-2.083-2.083-2.083" />
			<path stroke="white" d="M33.333 27.083H43.75v8.334H33.333a2.083 2.083 0 0 1-2.083-2.084v-4.166a2.084 2.084 0 0 1 2.083-2.084" />
		</g>
	</svg>
        </div>
        <h3 class="mb-2 text-xl font-bold dark:text-black">Fondo de Inversión</h3>
        <p class="text-gray-500 dark:text-gray-900">
          Obtén visibilidad sobre tus fondos de inversión y sigue su desempeño con facilidad.
        </p>
      </div>
      <div>
        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 64">
		<path fill="white" d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2M18.385 16.513L20.2 11.35l1.87 5.163h5.996l-4.84 3.352l1.836 5.253l-4.862-3.229l-4.862 3.229l1.836-5.253l-4.84-3.352zM4 31v-2h28V4c15.104 0 27.471 12.022 28 27z" />
	</svg>                 
        </div>
        <h3 class="mb-2 text-xl font-bold dark:text-black">Acciones Chilenas</h3>
        <p class="text-gray-500 dark:text-gray-900">
          Revisa tus inversiones en el mercado local con gráficos y datos actualizados.
        </p>
      </div>
      <div>
        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 1024 1024">
		<path fill="white" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64m0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372m47.7-395.2l-25.4-5.9V348.6c38 5.2 61.5 29 65.5 58.2c.5 4 3.9 6.9 7.9 6.9h44.9c4.7 0 8.4-4.1 8-8.8c-6.1-62.3-57.4-102.3-125.9-109.2V263c0-4.4-3.6-8-8-8h-28.1c-4.4 0-8 3.6-8 8v33c-70.8 6.9-126.2 46-126.2 119c0 67.6 49.8 100.2 102.1 112.7l24.7 6.3v142.7c-44.2-5.9-69-29.5-74.1-61.3c-.6-3.8-4-6.6-7.9-6.6H363c-4.7 0-8.4 4-8 8.7c4.5 55 46.2 105.6 135.2 112.1V761c0 4.4 3.6 8 8 8h28.4c4.4 0 8-3.6 8-8.1l-.2-31.7c78.3-6.9 134.3-48.8 134.3-124c-.1-69.4-44.2-100.4-109-116.4m-68.6-16.2c-5.6-1.6-10.3-3.1-15-5c-33.8-12.2-49.5-31.9-49.5-57.3c0-36.3 27.5-57 64.5-61.7zM534.3 677V543.3c3.1.9 5.9 1.6 8.8 2.2c47.3 14.4 63.2 34.4 63.2 65.1c0 39.1-29.4 62.6-72 66.4" />
	</svg>
        </div>
        <h3 class="mb-2 text-xl font-bold dark:text-black">Acciones del Extranjero</h3>
        <p class="text-gray-500 dark:text-gray-900">
          Accede a datos de tus inversiones globales y mantente informado de los movimientos del mercado internacional.
        </p>
      </div>
      <div>
        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 14 14">
		<g fill="currentColor">
			<path d="M7.5 13.499v-5.25a1.25 1.25 0 0 1 2.5 0v2.75h2a2 2 0 0 1 2 2v.5" />
			<path fill-rule="evenodd" d="M.031 4.424A4.423 4.423 0 1 1 8.676 5.75A2.5 2.5 0 0 0 6.25 8.249v.219A4.423 4.423 0 0 1 .031 4.424m4.423-3.238c.346 0 .625.28.625.625v.283a1.5 1.5 0 0 1 1.077.96a.625.625 0 1 1-1.179.417a.25.25 0 0 0-.232-.164H4.07a.153.153 0 0 0-.032.302l1.027.224a1.497 1.497 0 0 1 .014 2.921v.284a.625.625 0 0 1-1.25 0v-.283a1.5 1.5 0 0 1-1.076-.96a.625.625 0 0 1 1.179-.417a.25.25 0 0 0 .232.164h.581a.247.247 0 0 0 .052-.487L3.771 4.83a1.403 1.403 0 0 1 .058-2.753v-.266c0-.346.28-.625.625-.625M7.5 8.249v5.75H14v-1a2 2 0 0 0-2-2h-2v-2.75a1.25 1.25 0 0 0-2.5 0" clip-rule="evenodd" />
		</g>
	</svg>

        </div>
        <h3 class="mb-2 text-xl font-bold dark:text-black">ETF</h3>
        <p class="text-gray-500 dark:text-gray-900">
          Visualiza tus inversiones en fondos indexados con detalles sobre su desempeño y composición.
        </p>
      </div>
      <div>
        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 2048 2048">
		<path fill="white" d="M2048 384v640h-128V603l-768 768l-384-384l-675 674l-90-90l765-766l384 384l677-677h-421V384z" />
	</svg>
        </div>
        <h3 class="mb-2 text-xl font-bold dark:text-black">En Constante Expansión</h3>
        <p class="text-gray-500 dark:text-gray-900">
          Estamos continuamente agregando nuevos instrumentos para ofrecerte una experiencia de inversión más completa.
        </p>
      </div>
    </div>
  </div>
</section>



      <section class="bg-white dark:bg-black">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
            <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 class="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">Visualiza claramente</h2>
                <p class="mb-4">Sabemos lo importante que es para ti tener una visión completa de tus inversiones en un solo lugar. Por eso, nuestra plataforma te permite visualizar todos los tipos de fondos y activos, incluyendo fondos mutuos, fondos de inversión, acciones chilenas y del extranjero. Todo esto, diseñado para que puedas analizar y entender el rendimiento de tus activos de manera fácil y rápida. Porque entendemos que la claridad y el acceso a la información son esenciales para tus decisiones financieras, te ofrecemos una experiencia simplificada que reúne todo lo que necesitas en un solo vistazo.</p>
            </div>
            <div class="grid grid-cols-2 gap-4 mt-8">
                <img class="w-full rounded-lg" src="../assets/inicio1.jpg" alt="office content 1">
                <img class="mt-4 w-full lg:mt-10 rounded-lg" src="../assets/inicio2.jpg" alt="office content 2">
            </div>
        </div>
    </section>

</template>
  
  <script>
  export default {
    name: 'PaginaInicio',
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  /* Puedes añadir aquí algún estilo adicional si es necesario */
  </style>
  