<template>
  <div class="login-box-container">
  <div class="verify-2fa-box">

    <input v-model="otpCode" type="text" placeholder="Ingresa el código 2FA" />
    <button @click="handleVerify2FA" class="submit-button">
      <span></span>
          <span></span>
          <span></span>
          <span></span>
      Verificar Código 2FA</button>

    <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    onSuccess: {
      type: Function,
      required: true
    },
    context: {
      type: String,
      required: false,
      default: 'login' // Puede ser 'login' o 'reset-password'
    }
  },
  data() {
    return {
      otpCode: '',
      errorMessage: ''
    };
  },

  computed: {
    email() {
      return this.$store.state.email;
    }
  },

  methods: {
    async handleVerify2FA() {
      try {
        const endpoint = this.context === 'reset-password' ? '/verify-2fa-reset' : '/verify-2fa';
        const response = await this.$axios.post(endpoint, {
          email: this.email,
          code: this.otpCode
        });

        // Ejecuta la función de éxito pasada como prop
        this.onSuccess(response.data);

        // Limpiar el estado
        //this.$store.commit('clearEmail');
        this.$store.commit('clearQrCodeUrl');
      } catch (error) {
        this.errorMessage = error.response?.data?.message || 'Error al verificar el código 2FA';
      }
    }
  }
};
</script>

<style scoped>
/* Estilos CSS */
</style>
