<template>
    <div class="confirmation-box">
      <p>Registro Exitoso</p>
      <p>Te hemos enviado un correo electrónico para verificar tu cuenta. Por favor, revisa tu bandeja de entrada.</p>
      <router-link to="/">Volver al Inicio</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ConfirmacionRegistro'
  };
  </script>
  
  <style scoped>
  /* Estilos CSS para la página de confirmación */

  </style>