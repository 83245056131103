<template>
   <div class="login-box-container">
    <div class="login-box">
      <p v-if="status === 'loading'">Verificando...</p>
      <p v-if="status === 'success'">Tu correo electrónico ha sido verificado con éxito. Ahora puedes iniciar sesión.</p>
      <p v-if="status === 'error'">Hubo un problema con la verificación de tu correo. El token no es válido o ha expirado.</p>
      <router-link v-if="status === 'success'" to="/login">Ir a Iniciar Sesión</router-link>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        status: 'loading', // 'loading', 'success', 'error'
      };
    },
    async created() {
      const token = this.$route.query.token;
      try {
        const response = await this.$axios.get(`/verify-email?token=${token}`);
        this.status = 'success';
        console.log(response.data)
        // O redirige automáticamente después de un tiempo
         setTimeout(() => {
           this.$router.push('/login');
         }, 2000);
      } catch (error) {
        this.status = 'error';
      }
    }
  };
  </script>
  
  <style scoped>
 
  </style>
  