<template>
  <nav class="bg-white border-gray-200 px-4 sm:px-2 py-6 dark:bg-black fixed top-0 w-full z-50 shadow-md" >
    <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
      <!-- Logo -->
      <router-link to="/" class="flex items-center">
        <img src="../assets/letter-f.png" class="mr-2 h-6 sm:h-9" alt="Logo" />
        <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white truncate">Finally</span>
      </router-link>

      <!-- Login, Register, and Logout Buttons -->
      <div class="flex items-center lg:order-2">
        <!-- Si el usuario no está autenticado, muestra Login y Register -->
        <router-link
          v-if="!isAuthenticated"
          to="/login"
          class="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-xs sm:text-sm px-3 sm:px-4 py-1.5 sm:py-2 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
        >
          Ingresar
        </router-link>
        <router-link
          v-if="!isAuthenticated"
          to="/register"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs sm:text-sm px-3 sm:px-4 py-1.5 sm:py-2 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Registrar
        </router-link>

        <!-- Si el usuario está autenticado, muestra Cerrar Sesión -->
        <router-link
          v-if="isAuthenticated"
          @click.prevent="handleLogout"
          to="/"
          class="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-xs sm:text-sm px-3 sm:px-4 py-1.5 sm:py-2 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
        >
          Cerrar Sesión
        </router-link>

        <!-- Menú hamburguesa (para móviles) -->
        <button
          data-collapse-toggle="mobile-menu"
          type="button"
          class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span class="sr-only">Abrir menú principal</span>
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>

      <!-- Menú de navegación (para escritorio y móviles) -->
      <div class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu">
        <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-4 lg:mt-0">
          <li><router-link to="/" class="block py-2 pr-4 pl-3 text-gray-700 lg:text-blue-700 lg:p-0 dark:text-white truncate">Inicio</router-link></li>
          <li v-if="isAuthenticated"><router-link to="/create-grupos" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Crear Grupos</router-link></li>
          <li v-if="isAuthenticated"><router-link to="/add" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Agregar Fondos</router-link></li>
          <li v-if="isAuthenticated"><router-link to="/data" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Mis Fondos</router-link></li>
          <li v-if="isAuthenticated"><router-link to="/sessions" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Sesiones</router-link></li>
          <li v-if="!isAuthenticated"><router-link to="/about" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Quienes Somos</router-link></li>
          <li v-if="!isAuthenticated"><router-link to="/services" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Servicios</router-link></li>
          <li v-if="!isAuthenticated"><router-link to="/contact" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Contacto</router-link></li>
          <li v-if="!isAuthenticated"><router-link to="/tipo-fondos" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Aprende</router-link></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
name: 'HeaderGlobal',
computed: {
  ...mapState(['isAuthenticated'])
},
methods: {
  ...mapActions(['logout']),
  async handleLogout() {
    try {
      await this.$axios.post('/logout');
      this.logout();
      this.$router.push('/login');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  }
}
};
</script>

<style scoped>
/* Aquí puedes agregar tus estilos personalizados */
</style>
