<template>
  <div class="login-box-container">
    <div class="login-box">
      <p>Restablecer Contraseña</p>
      <form @submit.prevent="resetPassword">
        <div class="user-box">
          <input v-model="newPassword" type="password" required />
          <label>Nueva Contraseña</label>
        </div>
        <div class="user-box">
          <input v-model="confirmPassword" type="password" required />
          <label>Confirmar Contraseña</label>
        </div>
  
        <button type="submit" class="submit-button">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Restablecer</button>
      </form>
  
      <div v-if="message" :class="{'error': isError, 'success': !isError}">{{ message }}</div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        newPassword: '',
        confirmPassword: '',
        message: '',
        isError: false,
        token: ''
      };
    },
    mounted() {
      // Obtener el token de la URL
      this.token = this.$route.query.token;
    },
    methods: {
      async resetPassword() {
        if (this.newPassword !== this.confirmPassword) {
          this.message = 'Las contraseñas no coinciden.';
          this.isError = true;
          return;
        }
  
        try {
          const response = await this.$axios.post('/reset-password', {
            token: this.token,
            newPassword: this.newPassword
          });
          this.message = response.data.message;
          this.isError = false;
          localStorage.removeItem('token');
          // Redirigir al usuario al login después de un tiempo
          setTimeout(() => {
            this.$router.push('/login');
          }, 3000);
        } catch (error) {
          if (error.response && error.response.data) {
            this.message = error.response.data.message || 'Error al restablecer la contraseña.';
            this.isError = true;
          } else {
            this.message = 'Error al comunicarse con el servidor.';
            this.isError = true;
          }
        }
      }
    }
  };
  </script>
      <style scoped>

    </style>