<template>
    <div class="login-box-container">
      <div class="login-box">
        <p>Agregar Grupo de Inversiones</p>
  
        <!-- Campo para ingresar el nombre del grupo -->
        <div class="user-box">
          <h3>Nombre del Grupo</h3>
          <input v-model="nombreGrupo" type="text" required placeholder="Nombre del Grupo" />
        </div>
  
        <!-- Campo para ingresar la descripción del grupo -->
        <div class="user-box">
          <h3>Descripción del Grupo</h3>
          <textarea v-model="descripcionGrupo" rows="4" required placeholder="Descripción del Grupo" class="textarea-group"></textarea>
        </div>
  
        <button @click="agregarGrupo" type="button" class="submit-button">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Agregar Grupo
        </button>
  
        <!-- Mensajes de éxito o error -->
        <div v-if="mensajeExito" class="success">{{ mensajeExito }}</div>
        <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        nombreGrupo: '',
        descripcionGrupo: '',
        mensajeExito: '',
        errorMessage: ''
      };
    },
    methods: {
      async agregarGrupo() {
        // Validar que ambos campos estén completos
        if (this.nombreGrupo && this.descripcionGrupo) {
          try {
            // Hacer la petición POST usando Axios
            const response = await this.$axios.post('/agregar-grupo', {
              nombreGrupo: this.nombreGrupo,
              descripcionGrupo: this.descripcionGrupo
            });
  
            // Manejo de la respuesta exitosa
            this.mensajeExito = response.data.message || 'Grupo agregado con éxito.';
            this.errorMessage = '';
  
            // Limpiar los campos después de agregar
            this.nombreGrupo = '';
            this.descripcionGrupo = '';
          } catch (error) {
            // Manejo de errores
            if (error.response && error.response.data) {
              this.errorMessage = error.response.data.message || 'Error al agregar el grupo.';
            } else {
              this.errorMessage = 'Error al comunicar con el servidor.';
            }
            this.mensajeExito = '';
          }
        } else {
          this.errorMessage = 'Por favor completa todos los campos.';
          this.mensajeExito = '';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .user-box {
    position: relative;
    margin-bottom: 30px;
  }
  
  .user-box input, .user-box textarea {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
    letter-spacing: 1px;
  }
  
  .user-box textarea {
    resize: none; /* Evita que el textarea cambie de tamaño manualmente */
    height: 100px; /* Espacio adicional para escribir */
  }
  
  .user-box h3 {
    margin-bottom: 10px;
  }
  
  
  .success {
    color: #4caf50;
    margin-top: 10px;
  }
  
  .error {
    color: #f44336;
    margin-top: 10px;
  }
  </style>
  