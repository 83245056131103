<template>
    <div>
      <h2>Fondos del Usuario</h2>
      
      <div v-if="fondos.length">
        <ul>
          <li v-for="(fondo, index) in fondos" :key="index">
            <p><strong>Fondo:</strong> {{ fondo.fondo_nombre }}</p>
            <p><strong>Grupo:</strong> {{ fondo.grupo_nombre }}</p>
            <p><strong>Monto:</strong> {{ fondo.monto_local }} Pesos</p>
            <p><strong>Cantidad:</strong> {{ fondo.cantidad }}</p>
            <p><strong>Precio de Compra:</strong> {{ fondo.precio_compra }}</p>
            <p><strong>Precio Actual:</strong> {{ fondo.p_actual }}</p>

            <p><strong>Rentabilidad:</strong> {{ calcularRentabilidad(fondo.precioCompra, fondo.p_actual) }}%</p>
            <p><strong>Fecha:</strong> {{ new Date(fondo.fecha).toLocaleString() }}</p>
            <hr />
          </li>
        </ul>
      </div>
      
      <div v-else>
        <p>No tienes fondos agregados.</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
    
  export default {
    name: 'DataFondos',
    data() {
      return {
        fondos: [], // Aquí se almacenarán los fondos del usuario
        errorMessage: ''
      };
    },
    mounted() {
      this.obtenerFondos();
    },
    methods: {
      calcularRentabilidad(precioCompra, precioActual) {
        if (!precioCompra || !precioActual || precioCompra === 0) {
          return 0;
        }
        // Fórmula para calcular la rentabilidad
        const rentabilidad = ((precioActual - precioCompra) / precioCompra) * 100;
        return rentabilidad.toFixed(2); //
      },
  
      async obtenerFondos() {
        try {
  
  
          const response = await axios.get('/fondos', {
          });
  
          // Almacenar los fondos recibidos en la variable `fondos`
          this.fondos = response.data.fondos || [];
        } catch (error) {
          // Manejo de errores
          if (error.response && error.response.data) {
            this.errorMessage = error.response.data.message || 'Error al obtener los fondos.';
          } else {
            this.errorMessage = 'Error al comunicar con el servidor.';
          }
        }
      }
    }
  };
  </script>
  
  
  <style scoped>
  /* Agrega estilos aquí si es necesario */
  </style>