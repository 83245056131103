<template>
<!-- component -->
<div class="bg-white dark:bg-gray-900">
        <div class="flex justify-center h-screen">
            <div class="hidden bg-cover lg:block lg:w-2/3" style="background-image: url(https://images.unsplash.com/photo-1616763355603-9755a640a287?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)">
                <div class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
                    <div>
                        <h2 class="text-4xl font-bold text-white">Corto Plazo</h2>
                        
                        <p class="max-w-xl mt-3 text-white">Se refiere a un período de inversión que generalmente abarca desde unos pocos días hasta un máximo de 1 a 3 años. Las inversiones a corto plazo suelen enfocarse en obtener ganancias rápidas y suelen tener una mayor liquidez. Ejemplos comunes incluyen cuentas de ahorro, certificados de depósito a corto plazo, o la compra y venta de acciones en el mercado bursátil.</p>
                    </div>
                </div>
            </div>
            
            <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
                <div class="flex-1">
                    <div class="text-center">
                        <h2 class="text-4xl font-bold text-center text-gray-700 dark:text-white">Ingresar</h2>
                        
                        <p class="mt-3 text-gray-500 dark:text-gray-300">Completa tus datos</p>
                    </div>

                    <div class="mt-8">
                        <form @submit.prevent="login">
                            <div>
                                <label for="email" class="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email</label>
                                <input v-model="email" type="email" name="email" id="email" placeholder="example@example.com" class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                            </div>

                            <div class="mt-6">
                                <div class="flex justify-between mb-2">
                                    <label for="password" class="text-sm text-gray-600 dark:text-gray-200">Contraseña</label>
                                    <router-link to="/reset" class="text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline">Olvidaste tu contraseña?</router-link>
                                </div>

                                <input v-model="password" type="password" name="password" id="password" placeholder="Tu contraseña" class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                            </div>

                            <div class="mt-6">
                                <button
                                    class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                    Ingresar
                                </button>
                            </div>

                        </form>

                        <p class="mt-6 text-sm text-center text-gray-400">No tienes una cuenta? <router-link to="/register" class="text-blue-500 hover:underline">Regístrate</router-link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
//import VerificarDoble from './VerificarDoble.vue'; // Asegúrate de que la ruta es correcta
import UAParser from 'ua-parser-js';
export default {
  components: {
    //VerificarDoble
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      show2FA: false, // Estado para manejar la visualización del 2FA
      setup2FA: false, // Estado para manejar la configuración de 2FA
      qrCodeUrl: '', // URL del código QR para la configuración de 2FA
    };
  },
  methods: {
    async login() {
      try {

        // Obtención de datos de `UAParser`
        const parser = new UAParser();
        const uaResult = parser.getResult();
        const uaData = {
          browserName: uaResult.browser.name || 'Desconocido',
          deviceType: uaResult.device.type || 'Desktop',
          deviceModel: uaResult.device.model || 'Desconocido',
          deviceVendor: uaResult.device.vendor || 'Desconocido',
          osName: uaResult.os.name || 'Desconocido',
          osVersion: uaResult.os.version || 'Desconocido'
        };
        
        const response = await this.$axios.post('/login', {
          email: this.email,
          password: this.password,
          deviceData: uaData
        });

        if (response.data.requiresSetup) {
          this.qrCodeUrl = response.data.qrCodeUrl;
          this.setup2FA = true; // Mostrar la configuración de 2FA
        } else if (response.data.requires2FA) {
          this.show2FA = true; // Mostrar el componente 2FA
        } else if (response.data.loginSuccessful) {
          this.$router.push('/data'); // Redirigir al panel o página de inicio
        } else {
          this.errorMessage = 'Respuesta inesperada del servidor.';
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data.message || 'Error al iniciar sesión';
        } else {
          this.errorMessage = 'Error al comunicarse con el servidor.';
        }
      }
    },
    proceedToVerify2FA() {
      this.setup2FA = false; // Ocultar la configuración de 2FA
      this.show2FA = true; // Mostrar la verificación de 2FA
    },
    handle2FASuccess() {
      // Este método se ejecuta cuando el 2FA es exitoso
      this.$store.dispatch('checkAuthStatus'); // Verificar la autenticación
      this.$router.push('/data'); // Redirigir al panel o página de inicio
    }
  }
};
</script>


<style scoped>
/* Mantén los estilos existentes */

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
