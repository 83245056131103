<template>
    <div class="sessions">
      <h1>User Sessions</h1>
  
      <table v-if="sessions.length > 0">
        <thead>
          <tr>
            <th>Tipo de dispositivo</th>
            <th>Nombre Explorador</th>
            <th>Nombre OS</th>
            <th>Pais</th>
            <th>Ciudad</th>
            <th>Ultimo acceso</th>
            <th>Acciones</th> <!-- Nueva columna para las acciones -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="session in sessions" :key="session.id">
            <td>{{ session.device_type }}</td>
            <td>{{ session.browser_name }}</td>
            <td>{{ session.os_name }}</td>
            <td>{{ session.country }}</td>
            <td>{{ session.city }}</td>
            <td>{{ formatDate(session.last_accessed_at) }}</td>
            <td>
              <!-- Botón para cerrar la sesión -->
              <button @click="closeSession(session.id)">Cerrar sesión</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <p v-else>No se encontraron sesiones.</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        sessions: [], // Aquí se almacenarán las sesiones obtenidas del backend
      };
    },
    methods: {
      // Función para formatear la fecha de la última conexión
      formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(date).toLocaleDateString('es-CL', options);
      },
  
      // Función para cerrar una sesión específica
      async closeSession(sessionId) {
        try {
          await this.$axios.delete(`/del-user-session`, {
            data: {
              sessionId // Enviar el sessionId en el cuerpo de la solicitud
            }

          });
  
          // Eliminar la sesión cerrada de la lista local de sesiones
          this.sessions = this.sessions.filter(session => session.id !== sessionId);
        } catch (error) {
          console.error('Error al cerrar la sesión:', error);
        }
      }
    },
    async created() {
      try {
        // Hacer la solicitud al backend para obtener todas las sesiones del usuario
        const response = await this.$axios.get('/user-sessions', {
        });
  
        // Guardar las sesiones en el estado local
        this.sessions = response.data.sessions;
      } catch (error) {
        console.error('Error al obtener las sesiones del usuario:', error);
      }
    }
  };
  </script>
  
  
  <style scoped>
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  th, td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  
  h1 {
    margin-bottom: 20px;
  }
  </style>
  