<template>
  <HeaderGlobal></HeaderGlobal>
  <div v-if="shouldShowFooter" class="pt-[84px]"></div>
  <main><router-view></router-view></main>
  <FooterPrincipal v-if="shouldShowFooter"></FooterPrincipal>

</template>

<script>

import HeaderGlobal from './components/HeaderGlobal.vue';
import FooterPrincipal from './components/FooterPrincipal.vue'

export default {
  name: 'App',
  components: {
    HeaderGlobal,
    FooterPrincipal
  },
  computed: {
    shouldShowFooter() {
      // Lista de rutas donde NO quieres mostrar el footer
      const routesWithoutFooter = ['/login', '/register'];
      // Verifica si la ruta actual está en esa lista
      return !routesWithoutFooter.includes(this.$route.path);
    }

    
  }
}

</script>

<style>
@import './assets/style.css';
</style>
