<template>
  <div class="login-box-container">
  <div class="login-box">
    <h2>Configura la autenticación de dos factores</h2>
    <img :src="qrCodeUrl" alt="Código QR para 2FA" />

    <button class="submit-button"><router-link to="/verify-2fa">Siguiente</router-link></button>

    <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
  </div>
</div>
</template>

<script>
export default {
  computed: {
    qrCodeUrl() {
      return this.$store.state.qrCodeUrl;
    }
  }
};
</script>

<style scoped>
/* Estilos CSS */
</style>
