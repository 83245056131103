<template>
<!-- component -->
<section class="bg-white dark:bg-black">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
            <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 class="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">Quiénes Somos</h2>
                <p class="mb-4">Somos una plataforma digital diseñada para brindar a los inversores en Chile una manera clara y eficiente de visualizar todas sus inversiones y rentabilidades. Nuestro objetivo es simplificar el acceso a la información financiera, permitiéndote consultar el rendimiento de tus fondos en un solo lugar, de forma rápida y segura. Con una interfaz intuitiva y herramientas visuales avanzadas, hacemos que gestionar y entender tus inversiones sea más sencillo que nunca.</p>
            </div>
            <div class="grid grid-cols-2 gap-4 mt-8">
                
                <img class="mt-4 w-full lg:mt-10 rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png" alt="office content 2">
                <img class="w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png" alt="office content 1">
            </div>
        </div>
    </section>

<section class="bg-white dark:bg-black">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
            
            <div class="grid grid-cols-2 gap-4 mt-8">
                <img class="w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png" alt="office content 1">
                <img class="mt-4 w-full lg:mt-10 rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png" alt="office content 2">
            </div>

            <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 class="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">Nos Enfocamos en el Cliente</h2>
                <p class="mb-4">Nuestro enfoque está centrado en ofrecerte la mejor experiencia al gestionar y visualizar tus inversiones. Sabemos lo importante que es tener acceso a información precisa y actualizada, por lo que hemos creado una plataforma fácil de usar que te permite monitorear el rendimiento de tus fondos en tiempo real. Ponemos la transparencia y la claridad al frente, para que siempre tengas una visión completa de tus activos y puedas tomar decisiones financieras con total confianza.</p>
            </div>
        </div>
    </section>


</template>

<script>
export default {
name: 'TiposFondos',

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
