// store/index.js
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';  // Persistencia de estado opcional
import axios from 'axios';

const store = createStore({
  state() {
    return {
      isAuthenticated: false, // Estado de autenticación
    };
  },
  mutations: {
    // Mutación para establecer el estado de autenticación
    setAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    // Mutación para el logout (marcar como no autenticado)
    logout(state) {
      state.isAuthenticated = false;
    }
  },
  actions: {
    // Acción para verificar el estado de autenticación
    async checkAuthStatus({ commit }) {
      try {
        // Realiza una petición al backend para verificar si la sesión es válida (cookie de sesión)
        const response = await axios.get('/check-session'); // Endpoint que solo verifica si la sesión es válida
    
        if (response.data.isAuthenticated) {
          commit('setAuthenticated', true);  // Marcar como autenticado
        } else {
          commit('setAuthenticated', false); // No autenticado
        }
      } catch (error) {
        // En caso de error (sesión no válida o error en el servidor)
        commit('setAuthenticated', false);   // Marcar como no autenticado
      }
    },
    // Acción para el logout
    logout({ commit }) {
      commit('logout'); // Marcar como no autenticado localmente
      // Opcional: puedes hacer una llamada al servidor para cerrar la sesión si es necesario
      //axios.post('/api/auth/logout').catch(() => {}); // Llamada al servidor para cerrar la sesión
    }
  },
  plugins: [createPersistedState()]  // Plugin para persistir el estado en almacenamiento local
});

export default store;
